@import "primereact/resources/themes/saga-blue/theme.css";
@import "primereact/resources/primereact.min.css";

.p-dialog {
    border-radius: 20px;
    width: 100%;
    height: 100%;
}

.containerModal {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    border-radius: 50px;
}
.containerTituloEImagem {

    background-image: url('../../Assets/QuemSomos/imagemModalTrabalheNaFik.jpg');

    width: 40%;
    min-height: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: end;
    justify-content: center;

}



#pr_id_3_content {
    border-radius: 50px;
    border: solid 2px var(--primary-color);
    box-shadow: 0 0 0px var(--primary-color), 0 0 2px var(--primary-color);
    transition: box-shadow 0.3s;
}

.tituloEsquerdo {
    color: white;
    font-size: 2.5em;
    font-weight: 800;
    padding: 70px 0;
}

.containerDireito {
    padding: 30px 25px;
    font-size: 16px;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerbtnFechar {
    display: flex;
    justify-content: end;
    font-weight: 600;
    width: 100%;
}

.tituloDireito {
    font-weight: 1000;
    font-size: 1.5em;
    color: black;
    padding-bottom: 30px;
}

.estilizarPalavraDados {
    color: var(--primary-color);
}

.containerLabelItemSozinho {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.containerLabelCurriculo {
    display: flex;
    flex-direction: column;
    gap: 12px;


.custom-file-upload {
    border: 2px dashed #007BFF; 
    padding: 10px; 
    border-radius: 4px;
    font-size: 16px;
    background-color: transparent; 
    cursor: pointer; 
    display: inline-block; 
    text-align: center; 
color:#007BFF; 
}

input[type="file"] {
    display: none; 
}

}

.containerLabel {
    display: flex;
    flex-direction: column;
    width: 48%;
    justify-content: space-between;
    gap: 12px;
}

.containerDoisElementos {
    display: flex;
    justify-content: space-between;
}

.inputModalTrabalheConosco {
    width: 100%;
    height: 50px;
    border-radius: 9px;
    border: solid 2px var(--primary-color);
    box-shadow: 0 0 0px var(--primary-color), 0 0 7px var(--primary-color);
    transition: box-shadow 0.3s;
    outline: none;
    padding: 15px;
}

.inputModalTrabalheConosco:focus {
    box-shadow: 0 0 6px var(--primary-color), 0 0 8px var(--primary-color);
}

.inputModalTrabalheConosco::placeholder {
    font-size: 1.0em;
}

.btnEnviar {
    background-color: var(--primary-color);
    width: 100%;
    color: white;
    height: 60px;
    border-radius: 12px;
}

label {
    font-size: 0.8em;
    font-weight: 600;
}

.btnFechar {
    font-size: 18px;
    font-weight: 800;
}

form {
    display: flex;
    flex-direction: column;
    gap: 14px;
justify-content: c;

}

.containerAltura{
min-height: 100%;


}

@media (max-width: 650px) {
    .containerTituloEImagem {
        display: none;
    }
.containerDireito{
width: 100%;
}
}