@import "primereact/resources/themes/saga-blue/theme.css";
@import "primereact/resources/primereact.min.css";

input{
border: none;
}
.border-filled {
   border: solid 2px var(--primary-color);
    box-shadow: 0 0 0px var(--primary-color), 0 0 2px var(--primary-color);
    transition: box-shadow 0.3s;
}

.border-filled:focus{
    box-shadow: 0 0 6px var(--primary-color), 0 0 8px var(--primary-color);

}

.containerModal{

}
.p-dialog .p-dialog-header {
background-color:none; 
display:none;
}

.p-dialog {
border-radius: 20px; 
border-radius: 400px;
width: 100%;
height: 100%;
}

/* Estilizando para WebKit (Chrome, Edge, etc.) */
.p-dialog-content::-webkit-scrollbar {
    width: 4px; /* Barra bem fina */
}

.p-dialog-content::-webkit-scrollbar-track {
    background: transparent; /* Fundo transparente */
}

.p-dialog-content::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2); /* Barra translúcida */
    border-radius: 10px;
    transition: background 0.3s ease; /* Transição suave */
}

.p-dialog-content::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4); /* Cor escurecida ao interagir */
}

/* Para Firefox */
.p-dialog-content {
    scrollbar-width: thin; /* Fina */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* Barra clara com fundo transparente */
}

.containerModal{
display: flex;
flex-direction: row;
min-height: 100%;
border-radius: 50px;
}

.containerTituloEImagemBPO{
background-image: url('../../Assets/Robo/imagemMulherERobo.jpg');
width: 40%;
min-height:100% ;
background-size: cover; 
background-position: center; 
display:flex;
align-items: end;
justify-content: center;
}
#pr_id_3{
border-radius: 50px;
}
#pr_id_3_content{

border-radius: 50px 50px 50px 50px;
border: solid 2px var(--primary-color);
    box-shadow: 0 0 0px var(--primary-color), 0 0 2px var(--primary-color);
    transition: box-shadow 0.3s;

}

.tituloEsquerdo{
color: white;
font-size:2.5em;
font-weight: 800;
span{
color: var(--secondary-color);
}
padding: 70px 0px;
}

.containerDireito{
padding: 30px 25px 25px 25px;
font-size: 20px;;
width: 60%;
display:flex;
flex-direction: column;
align-items: center;
}
.containerbtnFechar{
display: flex;
justify-content: end;
font-weight: 600;
width: 100%;
}

.tituloDireito{
font-weight: 1000;
font-size: 1.5em;
color: black;
padding-bottom: 30px;;
}

.estilizarPalavraDados{
color: var(--primary-color);
}

.containerLabelItemSozinho{
display:flex;
flex-direction: column;
gap: 12px;
}
.containerLabel{
display:flex;
flex-direction: column;
width: 48%;
justify-content: space-between;
gap: 12px;

}
.containerDoisElementos{
display:flex;
justify-content: space-between;

}

.containerTextArea{
display:flex;
}

input {
    width: 100%;
    height: 50px;
    border-radius: 9px;
   
    outline: none; /* Remove o contorno ao focar */
    padding: 15px; /* Adiciona um pouco de espaçamento interno */
}

/* input:focus {
    box-shadow: 0 0 6px var(--primary-color), 0 0 8px var(--primary-color);
} */

input::placeholder{
font-size: 1.0em;
}
textarea::placeholder{
font-size: 1.0em;
}
select{
border: none;
width: 100%;
height: 50px;
border-radius: 9px;
border-radius: 9px;
/* border: solid 2px var(--primary-color);
box-shadow: 0 0 0px var(--primary-color), 0 0 7px var(--primary-color);
transition: box-shadow 0.3s; */
padding: 15px; 
outline: none; 
}
/* select:focus {
    box-shadow: 0 0 6px var(--primary-color), 0 0 8px var(--primary-color);
} */
.containerDadosCorporativos{
display:flex;
flex-direction: column;
width: 48%;
}

form{
display:flex;
flex-direction: column;
gap:14px;
}

textarea {
    width: 100%;
    height: 100px;
border: none;
    /* border: solid 2px var(--primary-color);
    box-shadow: 0 0 0px var(--primary-color), 0 0 7px var(--primary-color);
    transition: box-shadow 0.3s; */
    resize: none; /* Impede o redimensionamento */
    padding: 15px; /* Adiciona um pouco de espaçamento interno */
    border-radius: 5px; /* Arredonda os cantos (opcional) */
    outline: none; /* Remove o contorno ao focar */
}

/* textarea:focus {
    box-shadow: 0 0 6px var(--primary-color), 0 0 8px var(--primary-color);
} */

.btnEnviar{
background-color: var(--primary-color);
width: 100%;;
color: white;
height: 60px;
border-radius: 12px;
}

select{

    color: #999999; /* Cor do placeholder */

}
option{
color:black;
font-size: 1.5em;
}

label{
font-size: 0.8em;
font-weight: 600;
}

.btnFechar{
font-size: 18px;
font-weight: 800;
}

.inputCheckbox{
display:flex;
width: 20px;
height: 20px;

}
.containerCheckbox{
display:flex;
gap: 10px;;
}

.containerLabelCargo{
display:flex;
flex-direction: column;
justify-content: space-between;
gap: 12px;
}

@media (max-width: 650px) {
    .containerTituloEImagem {
        display: none;
    }
.containerDireito{
width: 100%;
}
}

@media (max-width: 1050) {
   .tituloEsquerdo{
font-size: 2em;
}
}
@media (max-width: 650px) {
    .containerTituloEImagemBPO {
        display: none;
    }
.containerDireito{
width: 100%;
}

}

.border-filled {
  border: solid 2px var(--primary-color);
    box-shadow: 0 0 0px var(--primary-color), 0 0 2px var(--primary-color);
    transition: box-shadow 0.3s;
}

.border-filled:focus {

    box-shadow: 0 0 6px var(--primary-color), 0 0 8px var(--primary-color);
border: solid 2px var(--primary-color);
    box-shadow: 0 0 0px var(--primary-color), 0 0 2px var(--primary-color);
    transition: box-shadow 0.3s;




}
